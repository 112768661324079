import React, {useState, useEffect} from "react";

import styles from "./index.module.css";
import Form from "../Form"
import Accordion from "../Accordion"

export default function ContentBlocks() {
  return (
    <div className={styles.ContentBlocks}>
      <section className={`container ${styles.ContentBlocks__section}`} id="contact-us">
        <span className={styles.ContentBlocks__heading}> <h2>Contact Us</h2></span>
        <p className={styles.ContentBlocks__paragraph}>
          Call us at the number below to get started.
          <br/>
          We are available Monday through Friday, 9 am to 4 pm MT
          <br/>
          After you complete your test, submit a photo of the test results through the “I Finished My Test” section of this site below to claim a $15 gift card.
        </p>
        <a className={styles.ContentBlocks__telephone} href="tel:1-833-669-8378"><div>1 (833) NMX-TEST</div></a>
        <p>1 (833) 669-8378</p>
      </section>
      <section className={`container ${styles.ContentBlocks__section}`} id="what-to-expect">
        <span className={styles.ContentBlocks__heading}> <h2>What to Expect</h2></span>
        <p className={styles.ContentBlocks__paragraph}>
          Maintaining a healthy HIV status for yourself and your partners involves checking and knowing your own status!
          We’ve made this possible no matter where you are in the state of New Mexico. A free, 20 minute OraQuick HIV test kit is available to ship to your address for your use in the privacy of your own home. This is the same oral HIV test used in many clinics and testing centers across the country.
          To perform the test, you’ll only need to be able to wipe a testing swab along the gumlines in your mouth.
          <br/>
          No blood and no sending samples to a lab.
        </p>
      </section>
      <section className={`container ${styles.ContentBlocks__section}`} id="finished-my-test">
        <span className={styles.ContentBlocks__heading}> <h2>I Finished My Test</h2></span>
        <p className={styles.ContentBlocks__paragraph}>
          Congratulations for taking charge of knowing your status and being in control of your health!
          <br/>
          Claim your $15 gift card. Be ready to take/upload a picture of your test and have the secret code you were assigned. Enter the code in the field below and click submit to start.
        </p>
        <Form/>
      </section>
      <section className={`container ${styles.ContentBlocks__section}`} id="local-testing">
        <span className={styles.ContentBlocks__heading}> <h2>Find a Local Testing Site</h2></span>
        <p className={styles.ContentBlocks__paragraph}>
          Prefer to go somewhere in-person to be tested?
          <br/>
          Visit <a href="https://nmhivguide.org/">this link</a> for an interactive search.
          <br/>
          <br/>
        </p>
      </section>
      <section className={`container ${styles.ContentBlocks__section}`} id="what-if">
        <span className={styles.ContentBlocks__heading}> <h2>What If</h2></span>
          <div className={styles.ContentBlocks__wrapper}>
            <Accordion
              heading="I come back as “reactive”?"
              text="Then we help you get started with a confirmatory test and into care."
            />
            <Accordion
              heading="I come back as “negative”?"
              text="Then we talk with you about PrEP or other ways to maintain your status."
            />
            <Accordion
              heading="I currently have HIV. Will this test tell me my viral load?"
              text="No, this test can only detect if there is evidence of HIV in the body."
            />
            <Accordion
              heading="I was exposed to HIV through a method known to transmit the virus, but it was less than 30 days ago?"
              text={["This test can only detect evidence of HIV that has been in the body for 30 days or more. This is called a ‘window period’. ", <a href='tel:1-800-669-8378'>Call us</a>, " so we can discuss your options."]}
            />
            <Accordion
              heading="I received my test and I’m too scared to begin?"
              text={["Give us ", <a href='tel:1-800-669-8378'>a call</a>,  " so we can answer your concerns and help ease the anxiety you may have. Having the test in your hand and outside of the shipping box may also give yourself more confidence. You are in control of your body and your health."]}
            />
            <Accordion
              heading="I changed my mind? I really don’t want this test anymore."
              text={["Don’t forget, there was a reason you wanted to take the test initially: to take charge of your health, know your status, and ensure that you are going to be the healthiest “you” possible! However, if you are sure that you won’t take the test, please ", <a href='tel:1-800-669-8378'>call us</a>, " to learn how to dispose of the test properly."]}
            />
          </div>
      </section>
    </div>
  );
};
