import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import styles from "./index.module.css";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Scroll from "../../assets/scroll.svg";


export default function ScrollMore() {
  return (
    <div className={styles.ScrollMore}>
      <AnchorLink to="/#contact-us">
        <Scroll/>
      </AnchorLink>
    </div>
  )
}
