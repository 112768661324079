import React from "react";
import ScrollMore from "../../components/ScrollMore";
import styles from "./index.module.css";

import Logo from "../../assets/betestednmlogo.svg";

const Hero = () => {
  return (
    <section className={styles.Hero}>
      <Logo alt="betestednm.org logo" className={styles.Hero__logo} />
      <svg
        className={styles.Hero__mountains}
        width="1530px"
        height="138px"
        viewBox="0 0 1530 138"
        version="1.1"
      >
        <g
          id="Artboard-Copy-18"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <polygon
            id="Rectangle-Copy-2"
            stroke="#000000"
            stroke-width="5"
            fill="#FFDA27"
            points="146.371094 30.6205405 175.015625 42.9925676 208 64.8091892 296.152344 94.4714865 373.049804 94.4714865 449 57.8766216 507.734209 23.9272413 519.246094 23.2725676 574.436331 56.1913884 614.566406 42.9925676 696.936644 20.7312714 748.796875 14.89 760.236624 18.5120883 769.628906 30.6205405 802.292969 56.1913884 914.378906 14.89 967.123197 10.9251696 999.238281 37.5648649 1099.93359 94.4714865 1201.33663 73.5013514 1261.83594 30.6205405 1284.11546 9.01945946 1312 3 1363.91797 30.6205405 1419.20313 73.5013514 1549 85.2110811 1549 148 -23 148 -23 117.902703 74.2421875 57.8766216 117.30826 30.6205405"
          >
            <animate
              id="anim1"
              attributeName="points"
              begin="0s"
              dur="80s"
              values="146.371094 30.6205405 175.015625 42.9925676 208 64.8091892 296.152344 94.4714865 373.049804 94.4714865 449 57.8766216 507.734209 23.9272413 519.246094 23.2725676 574.436331 56.1913884 614.566406 42.9925676 696.936644 20.7312714 748.796875 14.89 760.236624 18.5120883 769.628906 30.6205405 802.292969 56.1913884 914.378906 14.89 967.123197 10.9251696 999.238281 37.5648649 1099.93359 94.4714865 1201.33663 73.5013514 1261.83594 30.6205405 1284.11546 9.01945946 1312 3 1363.91797 30.6205405 1419.20313 73.5013514 1549 85.2110811 1549 148 -23 148 -23 117.902703 74.2421875 57.8766216 117.30826 30.6205405; 127.117188 16.4492187 179.382812 11.265625 229.695313 57.8766216 264.25 89.3102703 338.773438 64.8091892 427.128906 69 496.527344 50.7695313 553.691406 64.8091892 590.828125 125.363281 666.589844 125.363281 721.503906 84.3656357 743.628906 50.7695313 785.988281 42.9925676 872.667969 42.9925676 925.511719 105.6875 998.703125 81.445 1014.50781 42.9925676 1068.42578 30.6205405 1109.78125 64.8091892 1139.98828 117.902703 1224.42969 117.902703 1274.29297 64.8091892 1318.88672 50.7695313 1376.71484 50.7695313 1446.05859 42.9925676 1549 85.2110811 1549 148 -23 148 -23 117.902703 74.2421875 57.8766216 117.30826 30.6205405; 146.371094 30.6205405 175.015625 42.9925676 208 64.8091892 296.152344 94.4714865 373.049804 94.4714865 449 57.8766216 507.734209 23.9272413 519.246094 23.2725676 574.436331 56.1913884 614.566406 42.9925676 696.936644 20.7312714 748.796875 14.89 760.236624 18.5120883 769.628906 30.6205405 802.292969 56.1913884 914.378906 14.89 967.123197 10.9251696 999.238281 37.5648649 1099.93359 94.4714865 1201.33663 73.5013514 1261.83594 30.6205405 1284.11546 9.01945946 1312 3 1363.91797 30.6205405 1419.20313 73.5013514 1549 85.2110811 1549 148 -23 148 -23 117.902703 74.2421875 57.8766216 117.30826 30.6205405"
              repeatCount="indefinite"
            />
          </polygon>
          <polygon
            id="Rectangle-Copy-3"
            stroke="#000000"
            stroke-width="5"
            fill="#FFDA27"
            transform="translate(763.000000, 75.500000) scale(-1, 1) translate(-763.000000, -75.500000) "
            points="146.371094 30.6205405 175.015625 42.9925676 208 64.8091892 296.152344 94.4714865 373.049804 94.4714865 449 57.8766216 507.734209 23.9272413 519.246094 23.2725676 574.436331 56.1913884 614.566406 42.9925676 696.936644 20.7312714 748.796875 14.89 760.236624 18.5120883 769.628906 30.6205405 802.292969 56.1913884 914.378906 14.89 967.123197 10.9251696 999.238281 37.5648649 1099.93359 94.4714865 1201.33663 73.5013514 1261.83594 30.6205405 1284.11546 9.01945946 1312 3 1363.91797 30.6205405 1419.20313 73.5013514 1549 85.2110811 1549 148 -23 148 -23 117.902703 74.2421875 57.8766216 117.30826 30.6205405"
          >
            <animate
              id="anim1"
              attributeName="points"
              begin="0s"
              dur="80s"
              values="146.371094 30.6205405 175.015625 42.9925676 208 64.8091892 296.152344 94.4714865 373.049804 94.4714865 449 57.8766216 507.734209 23.9272413 519.246094 23.2725676 574.436331 56.1913884 614.566406 42.9925676 696.936644 20.7312714 748.796875 14.89 760.236624 18.5120883 769.628906 30.6205405 802.292969 56.1913884 914.378906 14.89 967.123197 10.9251696 999.238281 37.5648649 1099.93359 94.4714865 1201.33663 73.5013514 1261.83594 30.6205405 1284.11546 9.01945946 1312 3 1363.91797 30.6205405 1419.20313 73.5013514 1549 85.2110811 1549 148 -23 148 -23 117.902703 74.2421875 57.8766216 117.30826 30.6205405; 127.117188 16.4492187 179.382812 11.265625 229.695313 57.8766216 264.25 89.3102703 338.773438 64.8091892 427.128906 69 496.527344 50.7695313 553.691406 64.8091892 590.828125 125.363281 666.589844 125.363281 721.503906 84.3656357 743.628906 50.7695313 785.988281 42.9925676 872.667969 42.9925676 925.511719 105.6875 998.703125 81.445 1014.50781 42.9925676 1068.42578 30.6205405 1109.78125 64.8091892 1139.98828 117.902703 1224.42969 117.902703 1274.29297 64.8091892 1318.88672 50.7695313 1376.71484 50.7695313 1446.05859 42.9925676 1549 85.2110811 1549 148 -23 148 -23 117.902703 74.2421875 57.8766216 117.30826 30.6205405; 146.371094 30.6205405 175.015625 42.9925676 208 64.8091892 296.152344 94.4714865 373.049804 94.4714865 449 57.8766216 507.734209 23.9272413 519.246094 23.2725676 574.436331 56.1913884 614.566406 42.9925676 696.936644 20.7312714 748.796875 14.89 760.236624 18.5120883 769.628906 30.6205405 802.292969 56.1913884 914.378906 14.89 967.123197 10.9251696 999.238281 37.5648649 1099.93359 94.4714865 1201.33663 73.5013514 1261.83594 30.6205405 1284.11546 9.01945946 1312 3 1363.91797 30.6205405 1419.20313 73.5013514 1549 85.2110811 1549 148 -23 148 -23 117.902703 74.2421875 57.8766216 117.30826 30.6205405"
              repeatCount="indefinite"
            />
          </polygon>
        </g>
      </svg>

      <div className={styles.Hero__yellowbox}>
        <h1>
          Free HIV Testing <br /> Throughout New Mexico!
        </h1>
        <h3>
          <i>Live in the state of New Mexico?</i>
          <br />
          Be informed! Know your status with an in-home HIV test shipped to you.
          We are available to help through every step.
        </h3>
        <ScrollMore />
      </div>
    </section>
  );
};

export default Hero;
