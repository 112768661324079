import React from "react";
import Scrollspy from 'react-scrollspy'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styles from "./index.module.css";


export default function Nav() {

  return (
    <section className={styles.Nav}>
      <Scrollspy items={ ['contact-us', 'what-to-expect', 'finished-my-test', 'local-testing', 'what-if'] } currentClassName={styles.Nav__isCurrent} className={styles.Nav__linksList}>
        <li><AnchorLink to="/#contact-us" title="Contact Us"/></li>
        <li><AnchorLink to="/#what-to-expect" title="What to Expect"/></li>
        <li><AnchorLink to="/#finished-my-test" title="I Finished My Test"/></li>
        <li><AnchorLink to="/#local-testing" title="Find Local Testing Site"/></li>
        <li><AnchorLink to="/#what-if" title="What if…"/></li>
      </Scrollspy>
    </section>
  );
};
