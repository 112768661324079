import React from "react";

import styles from "./index.module.css";

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

export default function Form() {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleAttachment = (e) => {
    setState({ ...state, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => alert("Thank you, we have recieved your submission!"))
      .catch((error) => alert(error));
  };

  return (
    <form
      className={styles.Form}
      name="results"
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="results" />
      <p className="hidden">
        <label>
          Don’t fill this out if you're human:
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <p>
        <label>
          Upload Image:
          <input
            type="file"
            name="upload-image"
            accept="image/*"
            onChange={handleAttachment}
            required
          />
        </label>
      </p>
      <p>
        <label>
          Secret Code:
          <input
            placeholder="Enter Code Here"
            type="text"
            name="secret-code"
            onChange={handleChange}
            required
          />
        </label>
      </p>
      <p>
        <button className={styles.Form__submit} type="submit"><div>Submit</div></button>
      </p>
    </form>
  );
}
