import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import styles from "./index.module.css";


export default function Accordion({heading, text}) {
  const [setActive, setActiveState] = useState(false);

  const content = useRef(null);

  function toggleAccordion() {

    if (content.current.ariaExpanded === false) {
      setActiveState(setActive === true);
    } else {
      setActiveState(setActive === false);
    }
  }

  return (
    <div className={styles.Accordion}>
      <div className={styles.Accordion__item}>
        <button id="accordion-button-1" ref={content} onClick={toggleAccordion} aria-expanded={setActive}><span className={styles.Accordion__title}>{heading}</span><span className={`${setActive && styles.Accordion__iconActive} ${styles.Accordion__icon}`} aria-hidden="true"></span></button>
        <div className={`${setActive && styles.Accordion__contentActive} ${styles.Accordion__content}`}>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.any
};


Accordion.defaultProps = {
  heading: "Consectetur Risus Commodo Ornare",
  text: "Nullam id dolor id nibh ultricies vehicula ut id elit. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
};
