import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Nav from '../components/Nav'
import ContentBlocks from '../components/ContentBlocks'

export const IndexPageTemplate = ({
  title,
  heading,
  subheading
}) => {
  return(
    <>
      <Hero/>
      <Nav/>
      <ContentBlocks/>
    </>
  )
}

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        title
        heading
        subheading
      }
    }
  }
`
